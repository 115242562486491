/* 8px space 1 */
.cell-inactive {
  background-color: var(--gray-100) !important;
}

.column-name {
    font-size: 18px;
}

.pl-1

.pb-1 {
    padding-bottom: 8px;

}
.pb-2 {
    padding-bottom: 12px;
}
.pb-3 {
    padding-bottom: 16px;
}

.pb-4 {
    padding-bottom: 20px;
}

.pb-5 {
    padding-bottom: 20px;
}

.pb-6 {
    padding-bottom: 32px;
}

.license-information-type-1 {
    background-color: var(--gray-100)!important;
}

.license-information-type-2 {
    background-color: var(--gray-100)!important;
}

:global(.ui.grid).px-1 {
    padding-left: 8px;
    padding-right: 8px;
}

:global(.ui.grid).px-2 {
    padding-left: 12px;
    padding-right: 12px;
}

:global(.ui.grid).px-3 {
    padding-left: 16px;
    padding-right: 16px;
}

:global(.ui.grid).pb-6 {
    padding-bottom: 32px;
}

:global(.ui.grid).justify-center {
    justify-content:center;
}

:global(.ui.grid).justify-end {
    justify-content:end;
}

:global(.ui.grid).align-center {
    align-items: center;
}

:global(.ui.grid).align-end {
    align-items: flex-end;
}

.price {
    text-transform: uppercase;
    color: #ee7129;
}

.size-1 {
    font-weight: lighter;
    font-size: 12px;
}

.size-2 {
    font-weight: lighter;
    font-size: 14px;
}

.size-3 {
    font-size: 18.5px;
}


:global(.ui.table).plans-table tr td {
    border-top: 0px !important;
}
:global(.ui.table).plans-table tr th {
    border-bottom: 0px !important;
}

:global(.ui.grid).direction-column {
    flex-direction: column;
}

:global(.ui.table).plans-table {
    border-spacing: 10px;
    background-color: #ffffff;

}

:global(.ui.table).plans-table  tr:nth-child(2n+1) td {
    background-color: #f8fafc;
}

:global(.ui.table).plans-table  tr:last-child td {
    background-color: #ffffff;
}

:global(.ui.table).plans-table  tr td:first-child {
    /* border: 1px solid red!important; */
    /* min-width: 320px; */
}
.justify-self-end {
    justify-self: flex-end;
}
.justify-space-between {
    justify-content: space-between;
}

.flex {
    display: flex;
}

:global(.ui.table).plans-table  tr td:first-child .pl-1 {
    padding-left: 8px;
}

:global(.ui.table).plans-table  tr:nth-last-child(2) td {
    padding: 0;
}
:global(.ui.table).plans-table  tr:nth-last-child(2) td .btn-orange  {
    width: 100%;
    background-color: #ee7129;
    border: 1px solid #ee7129;
    text-transform: uppercase;
    color: #fff;
    font-weight: normal;
}

:global(.ui.table).plans-table  tr:nth-last-child(2) td .btn-orange.inverted  {
    background-color: transparent;
    border: 1px solid #ee7129;
    color: #ee7129!important;
}

.check, .invalid {
    width: 21px;
    height: auto;
}

.footnote {
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
}

.discrete-link {
    color: inherit;
}

:global(.ui.table).plans-table  tr:nth-last-child(2) td .btn-orange.green  {
    border-color: 1px solid var(--green-60);
    background-color: var(--green-60);
}