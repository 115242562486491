.dropdown-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}

.dropdown-container :global(.dropdown) {
    width: 300px;
}


.drodpown--cozy {
    display: flex;
    gap: 4px;
}

.dropdown__additional-info {
    color: var(--gray-200);
    display: flex;
    flex-wrap: wrap;
    flex-basis: 40%;
}