:root {
  --gray-dark: #29363d;
  --white: #fff;
  --gray: #536c79;
  --primary: #20a8d8;
  --secondary: #a4b7c1;
  --success: #4dbd74;
  --info: #63c2de;
  --warning: #ffc107;
  --danger: #f86c6b;
  --light: #f0f3f5;
  --dark: #29363d;
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #63c2de;
  --gray-100: #f0f3f5;
  --gray-200: #c2cfd6;
  --gray-300: #a4b7c1;
  --gray-400: #869fac;
  --gray-500: rgba(0, 0, 0, 0.5);
  --gray-600: #536c79;
  --gray-700: #3e515b;
  --gray-800: #29363d;
  --gray-900: #151b1e;
}


#root {
  height: 100%;
}

#root > div {
    height: 100%;
}

body {
  background-color: #f3f1ee;
}

.app {
  overflow: hidden;
}

#root {
  white-space: pre-line;
}

a {
  cursor: pointer;
}

.ui.dimmer {
  z-index: 2000;
}

.ui.loader {
  z-index: 1500;
}

/* To prevent showing popup under modal with dimmer */
.ui.popup {
  z-index: 3000;
}

.nav-dropdown-items .nav-link {
  padding-left: 40px;
}

/*Wide search results*/
.ui.search > .results {
  width: 100%;
}

.ui.modal {
  /* Fixes modals in Safari */
  /* https://github.com/Semantic-Org/Semantic-UI/issues/6251 */
  position: relative;
}

.ui.image {
  object-fit: cover;
}
.ui.mini.image {
  width: 35px;
  height: 35px;
}
.ui.tiny.image {
  width: 80px;
  height: 80px;
}

/* Masonry grid */
/* http://jsfiddle.net/oj4ew4Le/9/ */
.masonry.grid {
  display: block;
}

@media only screen and (min-width: 768px) {
  .masonry.grid {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }

  .ui.doubling.masonry.grid[class*='two column'] > .column {
    width: 100% !important;
  }
}

@media only screen and (min-width: 992px) {
  .masonry.grid {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

/* Prevent 'color: #fff' for some elements in Modal when prop basic is set to true */
.ui.basic.modal {
  color: inherit;
}

.ui.modal .ui.tabular.menu .item {
  background: #fff;
  opacity: 0.84;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ui.modal .ui.tabular.menu .item.active {
  opacity: 1;
}

.ui.form .inline.fields {
  align-items: baseline;
}

/* React-quill */
.ql-editor {
  min-height: 120px !important;
  max-height: 500px;
  overflow-y: auto;
}

/* keep parents of floated elements from collapsing */
.clearfix::after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
}

/* Make close icon for basic modal be arranged the same way like normal modal */
.ui.basic.modal > .close {
  top: -2.5rem;
  right: -2.5rem;
}

/* DayPicker */
.DayPickerInput-Overlay {
  /* Prevent overflow by slider checkboxes */
  z-index: 3;
}
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.DayPicker-Day {
  border-radius: 0 !important;
}
.DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

/* DatePicker */
.react-datepicker-popper {
  z-index: 5;
}
.react-datepicker__day--outside-month {
  color: var(--gray-500);
}

.react-datepicker--inline {
  border: none;
}
.react-datepicker--inline .react-datepicker__header {
  background: none;
  border-bottom: 1px solid var(--gray-100);
}

.language-dropdown__flag {
  height: 1em;
  margin-right: 0.5em;
}

.rbc-time-gutter .rbc-time-slot {
  background-color: transparent !important;
}

.ui.primary.button.danger {
  background-color: var(--danger);
}

.notification {
  border-radius: 12px!important;
}

.notification-info {
  background-color: var(--blue-10)!important;
  color: var(--neutral-05)!important;
  border: var(--neutral-05)!important;
}
.notification-info .notification-title {
  color: var(--blue-50)!important;
}

.notification-error {
  background-color: var(--red-05)!important;
  color: var(--red-70)!important;
  border: var(--neutral-05)!important;
}

.notification-error .notification-title {
  color: var(--red-70)!important;
}

.notification-success {
  background-color: var(--positive-background-color)!important;
  border: var(--positive-border-color)!important;
  color: var(--positive-text-color)!important;
}

.notification-success .notification-title {
  color: var(--positive-text-color)!important;
}

.inactive {
  color: var(--gray-300);
}

.modals .modal {
  padding-bottom: 20px;
}

.open-new-tab, a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 3px 3px 0px 5px;
}

.button [target="_blank"]::after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30" stroke="white"><path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path></svg>')
}

.error-msg {
  color: var(--danger);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-weight: normal;
}

.ui.secondary.pointing.menu .active.item {
  font-weight: normal;
}

.rbc-header {
  font-weight: normal;
}

.hidden-field {
  visibility: hidden;
  position: absolute;
  z-index: -9999;
  width: 0;
}

/* let react-date-picker input fill the entire column */
.column .react-datepicker-wrapper {
  width: 100%;
}