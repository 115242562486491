.wrapper :global(.field) {
    max-width: 500px;
}

.wrapper :global(.ui.header) {
    max-width: 500px;
}

.create-invoice-success-modal {
    .big-price {
        font-size: x-large;
        text-align: center;
        .key {
            text-transform: uppercase;
        }
        @media only screen and (max-width: 992px) {
            & {
                text-align: left!important;
            }
        }
    }
    .invoice-header {
        &:global(.ui.header) {
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
    .invoice-subheader {
        font-weight: normal;
    }
    .borderless-table {
        &:global(.ui.basic tbody tr td) {
            border: none;
        }
    }
    .text-transform-upper-case {
        text-transform: uppercase;
    }
 }