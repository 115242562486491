.success-image {
  width: 240px;
  margin-bottom: 40px;
}
.success-buttons-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success-button {
  display: block;
  margin-bottom: 30px;
}
.read-more {
  cursor: pointer;
}

.DateQuantities {
  --right-width: 80px;
  display: grid;
  gap: 12px;
}

.fluid {
  flex-wrap: wrap;
  margin-left: 0!important;
  margin-right: 0!important;
}

.fluid :global(.button) {
  margin-left: auto;
}

.fluid :global(.field) {
  flex-grow: 1;
}

.left {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 4px;
}

.left > div:nth-child(1) {
  flex-grow: 1;
  max-width: 16em;
}
  
.right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 80px;
}

.disabled {
  filter: opacity(0.5);
  pointer-events: none;
}

.quantity-row {
  margin-bottom: 0.5em;
  gap: 0.5em;
  flex-wrap: wrap;
}

.quantity-row .price {
  margin-left: auto;
}

.total-price {
  justify-content: flex-end;
  gap: 4px;
}

.small :global(.field .dropdown) {
  min-width: 0;
}

.row {
  display: flex;
  gap: 4px;
}

.small :global(.field) {
  margin-bottom: 0!important;
  min-width: 150px;
}

.DateQuantity {
  display: grid;
  gap: 8px;
}

.DateQuantitiesSubtotal {
  display: flex;
  justify-content: flex-end;
}

[disabled] {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.appliedCode {
  gap: 4px;
}

.sign {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.rebatePrice {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (min-width: 1200px) {
  .rebatePrice {
    min-width: var(--right-width);
  }

  .right {
    min-width: var(--right-width);
  }
}
