@media print {
    * { overflow: visible !important; } 
    .modal { page-break-after:always; }
    body * {
      visibility: hidden!important;
      border: none;
    }
    :global #root {
      display: none;
    }
    :global .ui.modals.dimmer {
        justify-content: start;
        border: none;
        position: relative;
    }
    :global .modals .modal {
        flex-grow: 1;
        box-shadow: none;
        height: auto;
    }
    :global .modal .header {
        border-bottom: 1px solid red;
    }
    :global .ui.modal .scrolling.content {
      max-height: unset;
      height: auto;
    }
    .billing, .billing * {
      visibility: visible!important;
    }
    .billing {
      position: relative;
      left: 0;
      top: 0;
      border: 1px solid transparent!important;
    }
  }


  :global .ui.dropdown.active.floated-dropdown {
    z-index: 100;
    position: absolute;
  }

  :global .ui.dropdown.active.floated-dropdown .scrolling.menu.visible {
    margin-top: -18px!important;
  }